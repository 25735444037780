import { FC, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

import { MenuLabels } from "@/models";
import { dataMenuLabels } from "@/data/menu.data";
import { Context } from "@/context";
import Home from "pages";

const Slug: FC = () => {
  const { state, dispatch } = useContext(Context);
  const router = useRouter();
  const [slug, setSlug] = useState<MenuLabels>(dataMenuLabels()[0]);

  useEffect(() => {
    const checkSlug = router?.query?.slug as string;
    if (checkSlug) {
      const foundLabel = dataMenuLabels().find((v) => v.slug === checkSlug);
      if (foundLabel) setSlug(foundLabel);
    }
  }, [router]);

  return <Home navRef={slug} />;
};

export default Slug;
